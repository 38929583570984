import { gql } from "@apollo/client";

export const UPLOAD_VIDEO = gql`
  mutation UPLOAD_VIDEO(
    $channel_id: Int!
    $title: String
    $description: String
    $audience: String
    $emailPreference: String
    $videoUrl: String
    $thumbnailUrl: String
    $videoId: String
  ) {
    updateVideoDetails(
      channel_id: $channel_id
      title: $title
      description: $description
      audience: $audience
      emailPreference: $emailPreference
      videoUrl: $videoUrl
      thumbnailUrl: $thumbnailUrl
      videoId: $videoId
    ) {
      video
    }
  }
`;

export const UPLOAD_PROGRESS_SUBSCRIPTION = gql`
  subscription UploadProgress($uploadId: String!) {
    uploadProgress(uploadId: $uploadId) {
      uploadId
      bytesProgress
    }
  }
`;

export const UPDATE_VIDEO_AND_TRANSCRIBE = gql`
  mutation ($videoId: String, $videoUrl: String, $thumbnailUrl: String) {
    updateVideoUrlsAndTranscribe(
      videoId: $videoId
      videoUrl: $videoUrl
      thumbnailUrl: $thumbnailUrl
    ) {
      id
    }
  }
`;

export const SINGLE_UPLOAD = gql`
  mutation ($file: Upload!, $uploadId: String!) {
    singleUpload(file: $file, uploadId: $uploadId) {
      videoUrl
      filename
      thumbnailUrl
      success
    }
  }
`;
