// external
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";

import { gql, useMutation, useSubscription } from "@apollo/client";

// local
import { Audience } from "./Audience";
import { Details } from "./Details";
import { modalUploadMessages } from "./messages";
import {
  SINGLE_UPLOAD,
  UPDATE_VIDEO_AND_TRANSCRIBE,
  UPLOAD_PROGRESS_SUBSCRIPTION,
  UPLOAD_VIDEO
} from "./queries";
import { Review } from "./Review";
import { StateUser, StateVideo } from "./types";
import { UploadButton } from "./UploadButton";
import { UploadButtonWrapper } from "./wrappers";

interface ModalUploadProps {
  /** The global user */
  user: StateUser;
}

const renderStep = (
  state: StateVideo,
  setState: React.Dispatch<React.SetStateAction<StateVideo>>,
  uploadFile: (files: FileList) => void,
  onDone: () => void,
  user: StateUser
) => {
  const steps = [
    <UploadButton
      uploadFile={uploadFile}
      state={state}
      setState={setState}
      user={user}
    />,
    <Details state={state} setState={setState} />,
    <Audience state={state} setState={setState} />,
    <Review state={state} setState={setState} onDone={onDone} />
  ];
  return steps[state.index] || steps[0];
};

export function ModalUploadContainer({ user }: ModalUploadProps) {
  const [show, setShow] = useState(false);
  const [state, setState] = useState<StateVideo>({ index: 0, title: "" });
  const [fileSize, setFileSize] = useState(0);
  const [uploadId] = useState(() =>
    Math.random().toString(36).substring(2, 15)
  );

  const [mutateUpload] = useMutation(UPLOAD_VIDEO);
  const [
    singleUploadMutate,
    { data: singleUploadData, loading: singleUploadLoading }
  ] = useMutation(SINGLE_UPLOAD);
  const [updateVideoUrlsAndTranscribe] = useMutation(
    UPDATE_VIDEO_AND_TRANSCRIBE
  );

  const { data: subscriptionData } = useSubscription(
    UPLOAD_PROGRESS_SUBSCRIPTION,
    {
      variables: { uploadId },
      skip: !uploadId
    }
  );

  const uploadFile = async (files: FileList) => {
    const file = files.item(0);
    if (!file) return;
    setFileSize(file.size);
    await singleUploadMutate({ variables: { file, uploadId } });
  };

  const handleClose = () => {
    setState({ index: 0, title: "" });
    setShow(false);
  };

  const onDone = async () => {
    await mutateUpload({
      variables: {
        channel_id: parseInt(user?.channelId || "1"),
        title: state.title,
        description: state?.description || "",
        audience: state?.audience || "Public",
        emailPreference: state?.emailPreference || "all",
        videoUrl: state?.videoUrl || "",
        thumbnailUrl: state?.thumbnailUrl || "",
        videoId: state?.videoId || ""
      }
    });
    setState({ index: 0, title: "" });
    setShow(false);
    toast("Video uploaded successfully!");
  };

  useEffect(() => {
    if (subscriptionData) {
      const percent = fileSize
        ? (subscriptionData.uploadProgress.bytesProgress / fileSize) * 100
        : 0;

      setState({
        ...state,
        progress: percent
      });
    }
  }, [subscriptionData, fileSize]);

  useEffect(() => {
    // Fix failure here
    if (singleUploadData?.singleUpload?.success == false) {
      toast("Video upload failed!");
    }
    if (singleUploadData?.singleUpload?.success == true) {
      updateVideoUrlsAndTranscribe({
        variables: {
          videoId: state?.videoId || "",
          videoUrl: singleUploadData.singleUpload.videoUrl,
          thumbnailUrl: singleUploadData.singleUpload.thumbnailUrl
        }
      }).then((res) => {
        setState({
          index: state.index,
          title: state?.title,
          description: state?.description,
          videoUrl: singleUploadData.singleUpload.videoUrl,
          thumbnailUrl: singleUploadData.singleUpload.thumbnailUrl,
          videoId: state?.videoId,
          audience: state.audience,
          emailPreference: state?.emailPreference
        });
      });
    }
  }, [singleUploadData]);

  if (!user?.email) return <React.Fragment />;

  return (
    <>
      <UploadButtonWrapper onClick={() => setShow(true)}>
        {modalUploadMessages.upload}
      </UploadButtonWrapper>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="xl"
        dialogClassName="modal-90w"
      >
        {renderStep(state, setState, uploadFile, onDone, user)}
      </Modal>
    </>
  );
}
